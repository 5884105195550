export const banks = [
  "Access Bank Diamond",
  "Access Bank Nigeria Plc",
  "Bond Bank",
  "Diamond Bank Plc",
  "Ecobank Nigeria",
  "Fidelity Bank Plc",
  "First Bank of Nigeria Plc",
  "First City Monument Bank",
  "GLOBUS BANK",
  "Guaranty Trust Bank Plc",
  "Heritage Bank",
  "Interswitch Financial Inclusion Services",
  "Keystone Bank Ltd",
  "MONIEPOINT MICROFINANCE BANK",
  "PARALLEX BANK LIMITED",
  "Polaris Bank",
  "PREMIUM TRUST BANK LTD",
  "Remita",
  "ROLEZ MFB",
  "Skye Bank Plc",
  "Stanbic IBTC Plc",
  "Standard Chartered Bank",
  "Sterling Bank Plc",
  "Union Bank Nigeria Plc",
  "United Bank for Africa Plc",
  "Unity Bank Plc",
  "WEMA Bank Plc",
  "Zenith Bank International",
];
