<template>
  <div :class="[isModal ? '' : 'sm:w-9/12 mx-auto sm:my-10', '']">
    <div
      class=""
      :class="[
        isModal ? '' : ' px-2 mb-10',
        'w-full flex flex-row items-start justify-between text-left ',
      ]"
    >
      <div v-if="!isModal">
        <h1
          class="sm:text-3xl text-2xl font-medium text-left title-font text-gray-700 mb-4"
        >
          Payment Details
        </h1>
        <p class="text-base leading-relaxed text-gray-400">
          Enter the payment details below and upload for approval.
        </p>
      </div>
    </div>

    <form @submit.prevent="uploadPayment">
      <div class="flex flex-wrap px-2 sm:px-0 sm:mx-auto sm:mb-2 -mx-2">
        <div class="p-2 sm:w-1/2 w-full sm:border-b sm:border-gray-50 sm:pb-4">
          <div class="w-full">
            <div class="relative text-left">
              <label for="batch_id" class="text-gray-400"> Batch Id </label>
              <input
                required
                name="batch_id"
                type="text"
                v-model="payment.batch_id"
                :disabled="batch_locked"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="Enter batch ID"
              />
              <div
                @click="batch_locked = !batch_locked"
                class="absolute right-3 top-8 cursor-pointer bg-white"
              >
                <svg
                  v-if="batch_locked"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-lock"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#595d61"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <rect x="5" y="11" width="14" height="10" rx="2" />
                  <circle cx="12" cy="16" r="1" />
                  <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-lock-open"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#595d61"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <rect x="5" y="11" width="14" height="10" rx="2" />
                  <circle cx="12" cy="16" r="1" />
                  <path d="M8 11v-5a4 4 0 0 1 8 0" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="p-2 sm:w-1/2 w-full mb-4 sm:mb-0 border-b border-gray-50 sm:pb-4"
        >
          <div class="w-full">
            <div class="relative text-left">
              <label for="payment_date" class="text-gray-400">
                Payment Date
              </label>

              <label class="text-gray-700" for="date">
                <input
                  required
                  type="date"
                  v-model="payment.payment_date"
                  class="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                />
              </label>
            </div>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full sm:border-b sm:border-gray-50 sm:pb-4">
          <div class="w-full">
            <div class="relative text-left">
              <label for="customer" class="text-gray-400">
                Customer name
              </label>
              <input
                required
                name="customer"
                type="text"
                v-model="payment.payer"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="Enter customer name"
              />
            </div>
          </div>
        </div>
        <div
          class="p-2 sm:w-1/2 w-full mb-4 sm:mb-0 border-b border-gray-50 sm:pb-4"
        >
          <div class="w-full">
            <div class="relative text-left">
              <label for="tin" class="text-gray-400">
                Tax Identification Number
              </label>
              <input
                required
                name="tin"
                type="text"
                v-model="payment.tin"
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="Enter TIN"
              />
            </div>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full sm:border-b sm:border-gray-50 sm:pb-4">
          <div class="w-full text-left relative">
            <label for="revenueItems" class="text-gray-400"> Bank </label>
            <select
              id="revenueItems"
              v-model="payment.bank"
              class="block w-full py-2.5 text-gray-600 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              name="revenueItems"
            >
              <option disabled :value="payment.bank">
                {{ payment.bank || "Select new item" }}
              </option>
              <option v-for="item in banks" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="p-2 sm:w-1/2 w-full mb-4 sm:mb-0 border-b border-gray-50 sm:pb-4"
        >
          <div class="w-full relative text-left">
            <label for="revenueItems" class="text-gray-400">
              Revenue item
            </label>

            <div class="relative">
              <input
                required
                name="revenueItems"
                type="text"
                v-model="payment.revenue_item"
                @focus="
                  searchingRevenueItems = true;
                  revenueItemSelected = false;
                "
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="Revenue Item"
              />
              <div
                v-if="searchingRevenueItems && filteredRevenueItems.length > 0"
                :class="{ 'h-40': filteredRevenueItems.length > 4 }"
                class="absolute bg-white z-20 rounded-md overflow-y-auto shadow-md w-full border"
              >
                <ul class="list">
                  <li
                    class="text-xs border-b px-3 py-1.5 hover:bg-gray-100 hover:font-semibold cursor-pointer"
                    v-for="(item, index) in filteredRevenueItems"
                    @click.prevent.stop="selectRevenueItem(item.revenue_item)"
                    :key="index"
                  >
                    {{ item.revenue_item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isModal"
          class="p-2 sm:w-2/2 w-full mb-4 sm:mb-0 border-b border-gray-50 sm:pb-4"
        >
          <div class="w-full relative text-left">
            <label for="revenueCategory" class="text-gray-400">
              Revenue category
            </label>

            <div class="relative">
              <input
                required
                name="revenueCategory"
                type="text"
                v-model="payment.revenue_category"
                @focus="
                  searchingRevenueCategories = true;
                  revenueCategorySelected = false;
                "
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="Revenue Category"
              />
              <div
                v-if="
                  searchingRevenueCategories &&
                  filteredRevenueCategories.length > 0
                "
                :class="{ 'h-40': filteredRevenueCategories.length > 4 }"
                class="absolute bg-white z-20 rounded-md overflow-y-auto shadow-md w-full border"
              >
                <ul class="list">
                  <li
                    class="text-xs border-b px-3 py-1.5 hover:bg-gray-100 hover:font-semibold cursor-pointer"
                    v-for="(item, index) in filteredRevenueCategories"
                    @click.prevent.stop="selectRevenueCategory(item)"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="w-full">
            <div class="relative text-left">
              <label for="gross" class="text-gray-400"> Gross </label>

              <div class="relative h-10">
                <input
                  id="grs"
                  required
                  name="gross"
                  type="text"
                  v-model="payment.gross"
                  class="absolute inset-0 h-full payment-upload-gross mask-input rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                  placeholder="0.00"
                />
                <input
                  v-model="formatGross"
                  type="float"
                  name=""
                  id=""
                  style="caret-color: #333 !important"
                  :class="` ${payment.gross.length > 6 ? 'px-6' : 'px-4'}  `"
                  class="absolute inset-0 h-full payment-upload-amount mask-input rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 bg-transparent text-transparent placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="relative text-left">
            <label for="amount" class="text-gray-400"> Amount </label>
            <div class="relative h-10">
              <input
                required
                id="amt"
                name="amount"
                type="text"
                v-model="payment.amount"
                class="payment-upload-amount h-full mask-input rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                placeholder="0.00"
              />
              <input
                v-model="formatAmount"
                type="float"
                name=""
                id=""
                style="caret-color: #333 !important"
                :class="` ${payment.amount.length > 6 ? 'px-6' : 'px-4'}  `"
                class="absolute inset-0 h-full payment-upload-amount mask-input rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 bg-transparent text-transparent placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
              />
            </div>
          </div>
        </div>
        <div
          class="sm:flex sm:flex-row px-2 sm:px-2 sm:justify-start sm:space-x-4 sm:container sm:mx-auto w-full"
        >
          <button
            type="button"
            @click.prevent="resetForm"
            class="px-8 py-2 sm:my-10 mt-8 w-full sm:w-1/2 font-semibold text-white text-md transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-red-600 hover:from-red-600 to-red-600 hover:to-red-600 focus:ring focus:ring-red-600 focus:outline-none"
          >
            <div>reset</div>
          </button>
          <button
            v-if="!isModal"
            type="submit"
            :disabled="uploadingPayment"
            class="px-8 py-2 sm:my-10 mt-4 mb-20 w-full sm:w-1/2 font-semibold text-white text-md transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-600 hover:from-green-600 to-green-600 hover:to-green-600 focus:ring focus:ring-green-600 focus:outline-none"
          >
            <div v-if="!uploadingPayment">Upload details</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
          <button
            v-if="isModal"
            type="button"
            @click.prevent="addPayment"
            :disabled="uploadingPayment"
            class="px-8 py-2 sm:my-10 mt-4 mb-20 w-full sm:w-1/2 font-semibold text-white text-md transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-600 hover:from-green-600 to-green-600 hover:to-green-600 focus:ring focus:ring-green-600 focus:outline-none"
          >
            <div v-if="!uploadingPayment">Add</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<!-- eslint-disable no-undef -->
<script setup>
import {
  reactive,
  ref,
  onBeforeMount,
  watch,
  computed,
  onMounted,
  onUpdated,
} from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import numeral from "numeral";
import { useRoute } from "vue-router";
import { banks } from "../../../data/banks";
// import VMasker from "vanilla-masker";

const store = useStore();
const route = useRoute();

const emit = defineEmits(["paymentAdded"]);
const props = defineProps({
  batchId: {
    type: String,
  },
  bank: {
    type: String,
  },
  revenueItem: {
    type: String,
  },
  revenueCategory: {
    type: String,
  },
});

const isModal = ref(false);

const revenueItems = ref([]);
const revenueCategories = ref([]);
// const amountState = ref(false);

const searchingRevenueItems = ref(false);
const searchingRevenueCategories = ref(false);
const uploadingPayment = ref(false);
const batch_locked = ref(true);
const revenueItemSelected = ref(false);
const revenueCategorySelected = ref(false);

const payment = reactive({
  payer: "",
  tin: "",
  revenue_item: "",
  revenue_category: "",
  gross: "",
  amount: "",
  batch_id: "",
  payment_date: "",
  bank: banks[0],
});

const formatAmount = ref("");
const formatGross = ref("");

const filteredRevenueItems = computed(() => {
  return revenueItems.value.filter((item) => {
    return item.revenue_item
      .toLowerCase()
      .includes(payment.revenue_item.toLowerCase());
  });
});
const filteredRevenueCategories = computed(() => {
  return revenueCategories.value.filter((item) => {
    return item.toLowerCase().includes(payment.revenue_category.toLowerCase());
  });
});

watch(formatAmount, (val) => {
  if (val >= 0) {
    payment.amount = numeral(val).format("0,0.00");
  }
});
watch(formatGross, (val) => {
  if (val >= 0) {
    payment.gross = numeral(val).format("0,0.00");
  }
});

watch(
  () => payment.revenue_item,
  (val) => {
    if (val.length > 0 && !revenueItemSelected.value) {
      searchingRevenueItems.value = true;
    } else searchingRevenueItems.value = false;
  }
);
watch(
  () => payment.revenue_category,
  (val) => {
    if (val.length > 0 && !revenueCategorySelected.value) {
      searchingRevenueCategories.value = true;
    } else searchingRevenueCategories.value = false;
  }
);

const selectRevenueItem = (item) => {
  payment.revenue_item = item;
  searchingRevenueItems.value = false;
  revenueItemSelected.value = true;
};
const selectRevenueCategory = (item) => {
  payment.revenue_category = item;
  searchingRevenueCategories.value = false;
  revenueCategorySelected.value = true;
};
const convertToNumber = (txt) => {
  if (txt.toString().includes(",")) {
    const num = txt.split(",");

    let finalNum = "";
    num.forEach((n) => {
      finalNum += n;
    });

    return parseFloat(finalNum);
  }
  return parseFloat(txt);
};
const validateInputs = () => {
  if (!parseFloat(payment.gross)) {
    return false;
  }
  if (!parseFloat(payment.amount)) {
    return false;
  }

  let pass = false;
  for (let v = 0; v < revenueItems.value.length; v++) {
    if (
      revenueItems.value[v].revenue_item.toLowerCase() ===
      payment.revenue_item.toLowerCase()
    ) {
      pass = true;
      break;
    }
  }
  for (let v = 0; v < revenueCategories.value.length; v++) {
    if (
      revenueCategories.value[v].toLowerCase() ===
      payment.revenue_category.toLowerCase()
    ) {
      pass = true;
      break;
    }
  }

  // eslint-disable-next-line no-useless-escape
  const specialChars = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
  const hyphenError =
    payment.batch_id.startsWith("-") || payment.batch_id.endsWith("-");

  if (specialChars.test(payment.batch_id.trim()) || hyphenError) {
    pass = false;
    Swal.fire({
      title: "Revenue House",
      icon: "warning",
      text: "Batch ID Error. Special characters and spaces not allowed except (-) and it must not be at the beginning or end...",
    });
    return false;
  }

  if (!pass) {
    Swal.fire({
      title: "Revenue House",
      icon: "warning",
      text: "Please enter a valid Revenue Item and try again...",
    });
    return false;
  }

  if (payment.tin.length < 7) {
    Swal.fire({
      title: "Revenue House",
      icon: "warning",
      text: "Please enter a valid TIN (No less than 7 digits) and try again...",
    });
    return false;
  }

  return true;
};
const addPayment = async () => {
  try {
    uploadingPayment.value = true;

    if (validateInputs()) {
      payment.gross = convertToNumber(payment.gross);
      payment.amount = convertToNumber(payment.amount);
      if (payment.batch_id === "") payment.batch_id = "single";

      emit("paymentAdded", { ...payment });
      resetForm();
    }
  } catch (error) {
    console.error(error);
  } finally {
    uploadingPayment.value = false;
  }
};
const uploadPayment = async () => {
  try {
    uploadingPayment.value = true;

    if (validateInputs()) {
      payment.gross = convertToNumber(payment.gross);
      payment.amount = convertToNumber(payment.amount);

      if (payment.batch_id === "") payment.batch_id = "single";
      let payload = { ...payment };
      delete payload.revenue_category;
      const result = await store.dispatch("uploadPaymentDetails", payload);
      const { status, msg } = result.data;
      if (status == "success") {
        Swal.fire({
          title: "Revenue House",
          icon: "success",
          text: msg,
        });
        resetForm();
      } else {
        Swal.fire({
          title: "Revenue House",
          icon: "error",
          text: msg || "Upload failed, please try again.",
        });
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    uploadingPayment.value = false;
  }
};
const resetForm = () => {
  payment.payer = "";
  payment.tin = "";
  payment.revenue_item = "";
  payment.gross = "";
  payment.amount = "";
  payment.payment_date = "";
  payment.bank = "";
  formatAmount.value = "";
  formatGross.value = "";

  if (!batch_locked.value || payment.batch_id === "single")
    payment.batch_id = "";
};
const fetchData = async () => {
  revenueItems.value = await store.dispatch(
    "fetchItemsToFilter",
    "Revenue Item"
  );
  revenueCategories.value = await store.dispatch(
    "fetchItemsToFilter",
    "Revenue Category"
  );
};
onBeforeMount(async () => {
  try {
    await fetchData();
  } catch (error) {
    console.log(error);
  }
});
onMounted(() => {
  if (route.path.includes("split")) {
    isModal.value = true;
  } else {
    isModal.value = false;
  }
});
onUpdated(() => {
  if (route.path.includes("split")) {
    payment.batch_id = props.batchId;
    payment.bank = props.bank;
    selectRevenueItem(props.revenueItem);
    selectRevenueCategory(props.revenueCategory);
  }
});
</script>

<style></style>
